import React from "react"
import styled from "styled-components"
import {Container} from "reactstrap"
import {media} from "utils/Media"
import leftLine from "images/lines-left.svg"
import rightLine from "images/lines-right.svg"

const HeadingContainer = styled.div` 
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        &.lines {
            @media ${media.sm} {
                &:before {
                    content: '';
                    margin: 0 20px 0 0;
                    flex: 1 0 20px;
                    background: url(${leftLine}) no-repeat center right;
                    background-size: cover;
                    height: 4px;
                }

                &:after {
                    content: '';
                    margin: 0 0 0 20px;
                    flex: 1 0 20px;
                    background: url(${rightLine}) no-repeat center left;
                    background-size: cover;
                    height: 4px;
                }
            }
        }
        
        &.color--white {
            color: ${props => props.theme.colors.white};
        }
    }
`


const HeadingSpecial = (props) => {

    let HeadingTag
    const acceptedTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']

    if (acceptedTags.indexOf(props.headingTag) >= 0) {
        HeadingTag = props.headingTag
    } else {
        HeadingTag = 'h2'
    }

    return (
        <HeadingContainer>
            <HeadingTag className={`${props.className} ${props.lines && 'lines'}`} dangerouslySetInnerHTML={{__html: props.title}}/>
        </HeadingContainer>
    )
}

HeadingSpecial.defaultProps = {
    lines: true
}

export default HeadingSpecial