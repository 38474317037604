import snowFlakesXs from "../../images/snowflakes-mobile.svg"
import snowFlakesXl from "../../images/snowflakes-desktop.svg"
import styled from "styled-components";
import {media} from "utils/Media"

const SnowFlakes = styled.div`
    background: url("${snowFlakesXs}") repeat 50% 120%;
    background-size: 240%;
    
    @media ${media.sm} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 290%;
    }
    
    @media ${media.md} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }
    
    @media ${media.lg} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }
    
    @media ${media.xl} {
        background: url("${snowFlakesXl}") repeat 48% 17%;
        background-size: 180%;
    }
`

export default SnowFlakes