import React, {Component} from 'react';
import styled from "styled-components"
import separatorLine from "images/separator-line.svg"
import {media} from "utils/Media"

const PaginatorWrapper = styled.div`
    margin: 0.5rem auto;
    width: 72px;
    
    @media ${media.lg} {
      margin: 1rem auto;
      width: 95px;
    }
`

class Paginator extends Component {
    render() {
        return (
            <PaginatorWrapper>
                <img src={separatorLine} alt="" className="d-block"/>
            </PaginatorWrapper>
        );
    }
}

export default Paginator;