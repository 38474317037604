import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "../../components/Layout/Layout"
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {media} from "utils/Media"
import HeadingSpecial from "../../components/shared/HeadingSpecial";
import SnowFlakes from "../../components/shared/SnowFlakes";
import iconDownload from "../../images/icon-download.svg";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Paginator from "../../components/shared/Paginator";

const pageSlug = "wellbeing-resources"
const pageTitle = "Wellbeing Resources"
const categorySlug = "teaching"
const categoryTitle = "teaching"

const Padding = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`
const Content = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 2rem;
`
const BoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    align-items: stretch;
`

const Box = styled.div`
    filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
    background: white;
    padding: 1rem;
    width: 100%;
    margin: 0.5rem auto;

    @media ${media.sm} {
        margin: 1rem;
    }

    @media ${media.md} {
        padding: 2rem;
    }

    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        font-size: 24px;
        margin-bottom: 1rem;
        text-align: center;

        @media ${media.sm} {
            font-size: 30px;
            line-height: 35px;
        }

    }

    p {
        @media ${media.lg} {
             min-height: 85px;
        }

        @media ${media.xl} {
            min-height: 65px;
        }
    }
`

const InnerBox = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
        @media ${media.md} {
            width: calc(50% - 2rem);
            max-width: initial;
        }
    }
`

class Wellbeing extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: "enrichment-materials",
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="teaching-resources"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                    <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>

                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageLg)} alt="" title=""
                             className="img-fluid d-none d-lg-block"/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageMd)} alt="" title=""
                             className="img-fluid d-none d-sm-block d-lg-none "/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageXs)} alt="" title=""
                             className="img-fluid d-block d-sm-none"/>
                        <Padding>
                        <p className="font--regular narrow text-center mx-auto my-5">
                            To mark the opening of Disney’s FROZEN The Musical in the West End, Disney Theatrical Group
                            has created a resource for primary schools that supports wellbeing at Key Stage 2. Using
                            familiar characters from FROZEN, pupils can explore the landscape of emotions helping them
                            build resilience, create strong friendships and celebrate the fact that we are all unique!
                        </p>
                            <Paginator/>
                        </Padding>
                        <BoxWrapper>
                            <Box>
                                <h3>Download all Wellbeing Resources</h3>
                                <a href="/pdf/All-wellbeing-resources.zip" download target="_blank"
                                   className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    Download ZIP
                                    </span>
                                    <img src={iconDownload} alt="" title=""/>
                                </a>
                                <p className="pt-4 mt-4 mb-2" style={{    minHeight: "auto"}}>You can also download each wellbeing resource individually</p>
                                <InnerBox>
                                    <a href="/pdf/1-Disney_s-Frozen-the-Musical-Wellbeing-Programme-Teacher-Notes-v12.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                        teacher notes
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/2-Disney_s-Frozen-the-Musical-Wellbeing-Resource-PowerPoint-Presentation-v7.pptx"
                                       download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    powerpoint presentation
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/Frozen_Oct20_A3-PrintandCutOut-Puppet_AW.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    puppet templates
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/4-Disney_s-Frozen-the-Musical-Wellbeing-Scenario-Cards-A3-v4.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                   scenario cards
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/3-Disney_s-Frozen-the-Musical-Wellbeing-Emotion-Cards-A3-v5.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    emotion cards
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/Activity-sheets.zip" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                   activity sheets x 5
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/6-Disney_s-Frozen-the-Musical-Secret-Kindness-Mission-Log-v6.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    secret kindness mission log
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/7-Activity-Sheet-Disney_s-Frozen-the-Musical-Olafs-Thought-Jar-Labels.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    olaf’s thought jar labels
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                    <a href="/pdf/5-Disney_s-Frozen-Wellbeing-Check-In-Board-Labels-v4.pdf" download target="_blank"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    check-in board labels
                                    </span>
                                        <img src={iconDownload} alt="" title=""/>
                                    </a>
                                </InnerBox>
                            </Box>
                        </BoxWrapper>
                    </Content>
                </SnowFlakes>
            </Layout>
        )
    }
}


const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                TopImageLg: file(relativePath: { eq: "TeachingResources/header-wellbeing-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                TopImageMd: file(relativePath: { eq: "TeachingResources/header-wellbeing-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                TopImageXs: file(relativePath: { eq: "TeachingResources/header-wellbeing-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Wellbeing data={data}/>
        )}
    />
)
export default Query
